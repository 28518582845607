<template>
    <div class="myOrder">
        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部订单" name="0"></el-tab-pane>
                <el-tab-pane label="待付款" name="1"></el-tab-pane>
                <el-tab-pane label="待发货" name="2"></el-tab-pane>
                <el-tab-pane label="待收货" name="3"></el-tab-pane>
                <el-tab-pane label="待评价" name="4"></el-tab-pane>
                <!-- <el-tab-pane label="已取消" name="5"></el-tab-pane> -->
            </el-tabs>
        </div>
        <div class="search">
            <el-input placeholder="输入商品标题或订单进行搜索" v-model="keyword">
                <el-button slot="append" @click="clickSearch">订单搜索</el-button>
            </el-input>
            <!-- <span>精简筛选条件</span> -->
        </div>
        <div class="conditions" v-if="false">
            <div class="item">
                <span>订单类型</span>
                <el-select v-model="condition.orderType" placeholder="请选择">
                    <el-option
                    v-for="item in orderTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>成交时间</span>
                <el-date-picker
                v-model="condition.daterange"
                type="daterange"
                range-separator="至"
                start-placeholder="请选择时间范围起始"
                end-placeholder="请选择时间范围结束">
                </el-date-picker>
            </div>
            <div class="item">
                <span>卖家昵称</span>
                <el-input v-model="condition.nickname" placeholder="请输入内容"></el-input>
            </div>
            <div class="item">
                <span>评价状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>交易状态</span>
                <el-select v-model="condition.tradingStatus" placeholder="请选择">
                    <el-option
                    v-for="item in tradingStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>售后服务</span>
                <el-select v-model="condition.afterSale" placeholder="请选择">
                    <el-option
                    v-for="item in afterSaleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="conditions" v-if="false">
            <!-- <div class="item">
                <span>订单类型</span>
                <el-select v-model="condition.orderType" placeholder="请选择">
                    <el-option
                    v-for="item in orderTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
            <div class="item">
                <span>支付状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>发货状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>下单时间</span>
                <el-date-picker
                v-model="condition.daterange"
                type="daterange"
                range-separator="至"
                start-placeholder="请选择时间范围起始"
                end-placeholder="请选择时间范围结束">
                </el-date-picker>
            </div>
            <!-- <div class="item">
                <span>卖家昵称</span>
                <el-input v-model="condition.nickname" placeholder="请输入内容"></el-input>
            </div> -->
            <div class="item">
                <span>发货状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>结算状态</span>
                <el-select v-model="condition.tradingStatus" placeholder="请选择">
                    <el-option
                    v-for="item in tradingStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- <div class="item">
                <span>售后服务</span>
                <el-select v-model="condition.afterSale" placeholder="请选择">
                    <el-option
                    v-for="item in afterSaleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
        </div>
        <div class="list">
            <div class="thead cell">
                <div class="goods">商品</div>
                <div class="unitPrice">单价</div>
                <div class="goodsNumber">数量</div>
                <div class="receiveUser">收货人</div>
                <div class="goodsOpe">商品操作</div>
                <div class="payment">实付款</div>
                <div class="tradingStatus">交易状态</div>
                <div class="tradingOpe">交易操作</div>
            </div>
            <div class="tbody">
                <div class="orderItem" v-for="(order,i) in orderList" :key="i">
                    <div class="info">
                        <div class="left">
                            <!-- <el-checkbox v-model="order.checked"></el-checkbox> -->
                            <span>{{order.createTime.split(' ')[0]}}</span>
                            <span>订单号：{{order.orderSn}}</span>
                        </div>
                        <!-- <div class="right">
                            <i class="el-icon-delete" @click="clickCancel(order)"></i>
                        </div> -->
                    </div>
                    <div class="goodsItem cell" v-for="(goodsItem,j) in order.orderGoodsList" :key="j">
                        <div class="goods">
                            <img :src="goodsItem.goods.cover"/>
                            <div class="goodsInfo">
                                <div class="name">{{goodsItem.goods.name}}</div>
                                <div class="otherInfo">
                                    <span>颜色分类：{{goodsItem.color}}</span>
                                </div>
                                <div class="deliveryTime">发货时间：预计24小时内发货</div>
                            </div>
                        </div>
                        <div class="unitPrice">￥{{goodsItem.goods.ourShopPrice}}</div>
                        <div class="goodsNumber">{{goodsItem.count}}</div>
                        <div class="receiveUser" :class="{'setDisable':j!=0}">{{order.receiveUser}}</div>
                        <div class="goodsOpe" :class="{'setDisable':j!=0}">
                            <div class="ope_1" @click="clickAfterSale(1,goodsItem)">申请售后</div>
                            <!-- <div @click="clickAfterSale(2)">投诉商家</div> -->
                        </div>
                        <div class="payment" :class="{'setDisable':j!=0}">
                            {{goodsItem.goodsPrice}}
                        </div>
                        <div class="tradingStatus" :class="{'setDisable':j!=0}">
                            <div v-if="order.status=='N'">已取消</div>
                            <div v-if="order.status=='Y'">
                                <!-- <div>交易成功</div> -->
                                <div v-show="order.payStatus==0&&order.shippingStatus==0">未支付</div>
                                <div v-show="order.payStatus==1&&order.shippingStatus==0">已支付</div>
                                <div v-show="order.payStatus==2&&order.shippingStatus==0">部分支付</div>
                                <!-- <div v-show="order.shippingStatus==0&&order.payStatus!=0">未发货</div> -->
                                <div v-show="order.shippingStatus==1&&order.payStatus!=0">已发货</div>
                                <div v-show="order.shippingStatus==2&&order.payStatus!=0">部分发货</div>
                                <div @click="clickOrderDetail(order)">订单详情</div>
                                <!-- <div>查看物流</div> -->
                            </div>
                            <!-- <div @click="clickOrderDetail(order)">订单详情</div> -->
                        </div>
                        <div class="tradingOpe" :class="{'setDisable':j!=0}">
                            <!-- <div v-if="order.status=='Y'"> -->
                                <el-button @click="clickComment(order)">评价</el-button>
                                <div class="payfor" @click="clickPayfor(order)" v-show="order.payStatus==0">继续支付</div>
                                <!-- <div class="invoice">申请开票</div> -->
                                <div class="voucher" @click="clickVoucher(order)">上传凭证</div>
                                <!-- <div class="printOrder" @click="clickPrint(order)">打印订单</div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <!-- <div>
                        展开全部{{order.orderGoodsList.length}}个商品
                    </div> -->
                </div>

                <div class="empty" v-show="orderList.length==0">
                    <img src="../../assets/imgs/place.png" />
                    <div>暂无订单</div>
                </div>
            </div>
        </div>

        <div class="pagi">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>

        <el-dialog
            title="申请售后"
            :visible.sync="dialogVisible"
            width="687px"
            :before-close="handleClose1">
            <div class="afterSaleDia">
                <el-form ref="form" label-position="left" :model="afterSaleData" label-width="80px">
                    <el-form-item label="售后类型:">
                        <el-select v-model="afterSaleData.type" placeholder="点击选择">
                            <el-option
                            v-for="item in afterSaleOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注信息:">
                        <el-input type="textarea" v-model="afterSaleData.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="上传附件:" prop="annex">
                        <el-upload
                            class="avatar-uploader"
                            action="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/"
                            :show-file-list="true"
                            list-type="picture-card"
                            :limit="5"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :data="otherParam">
                            <!-- <img v-if="afterSaleData.annex" :src="afterSaleData.annex" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <span>点击上传</span>
                            </el-upload>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button type="primary" @click="submitAfterSale">确 定</el-button>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 上传凭证 -->
        <!-- <el-dialog
            title="上传凭证"
            :visible.sync="dialogVisible2"
            width="687px"
            :before-close="handleClose1">
            <div class="upVoucherDia">
                <el-form ref="form" label-position="left" :model="voucherData" label-width="80px">
                    <el-form-item label="凭证:" prop="annex">
                        <el-upload
                            class="avatar-uploader"
                            action="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/"
                            :show-file-list="true"
                            list-type="picture-card"
                            :limit="5"
                            :on-success="handleAvatarSuccess2"
                            :before-upload="beforeAvatarUpload"
                            :data="otherParam">
                            <span>点击上传</span>
                            </el-upload>
                            <div style="margin-top:5px;color:#999">最多可上传5张图片</div>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button type="primary" @click="submitVoucher">确 定</el-button>
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                </div>
            </div>
        </el-dialog> -->

        <el-dialog
            title="上传凭证"
            :visible.sync="dialogVisible2"
            width="687px"
            :before-close="handleClose1">
            <div class="upVoucherDia">
                <el-form ref="voucherForm" label-position="right" :model="voucherData" :rules="upVoucherRules" label-width="100px">
                    <el-form-item label="订单号">
                        <!-- <el-input v-model="voucherData.orderSn"></el-input> -->
                        <div>{{chosenItem2.orderSn}}</div>
                    </el-form-item>
                    <el-form-item label="收款金额" prop="receiveAmount" required>
                        <el-input v-model="voucherData.receiveAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="收款方式" prop="receiveAccountType" required>
                        <el-select v-model="voucherData.receiveAccountType" placeholder="请选择">
                            <el-option v-for="item in receiveAccountTypeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收款账户">
                        <el-select v-model="voucherData.receiveAccountId" placeholder="请选择">
                            <el-option v-for="item in receiveAccountOpt" :key="item.value" :label="item.accountNumber" :value="item.receiveAccountId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="付款账户" prop="payAccount" required>
                        <el-input v-model="voucherData.payAccount"></el-input>
                    </el-form-item>
                    <el-form-item label="交易流水号">
                        <el-input v-model="voucherData.transSerialNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="关联凭证" prop="annex" required>
                        <el-upload
                            class="avatar-uploader"
                            action="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/"
                            :show-file-list="true"
                            list-type="picture-card"
                            :limit="5"
                            :on-success="handleAvatarSuccess2"
                            :before-upload="beforeAvatarUpload"
                            :data="otherParam">
                            <!-- <img v-if="voucherData.annex" :src="voucherData.annex" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <span>点击上传</span>
                            </el-upload>
                            <div style="margin-top:5px;color:#999">最多可上传5张图片</div>
                    </el-form-item>
                    <el-form-item label="备注:">
                        <el-input type="textarea" v-model="voucherData.remark"></el-input>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button type="primary" @click="submitVoucher">确 定</el-button>
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 评价 -->
        <el-dialog
            title=""
            :visible.sync="dialogVisible3"
            width="687px"
            :before-close="handleClose2">
            <div class="commentsDia">
                <el-form ref="form" label-position="left" :model="commentsData" label-width="80px">
                    <el-form-item label="评价:">
                        <el-input type="textarea" v-model="commentsData.content"></el-input>
                    </el-form-item>
                    <el-form-item label="图片:" prop="annex">
                        <el-upload
                            class="avatar-uploader"
                            action="https://ai-smart-mall.oss-cn-chengdu.aliyuncs.com/"
                            :show-file-list="true"
                            list-type="picture-card"
                            :limit="5"
                            :on-success="handleAvatarSuccess3"
                            :before-upload="beforeAvatarUpload"
                            :data="otherParam">
                            <!-- <img v-if="commentsData.annex" :src="commentsData.annex" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            <span>点击上传</span>
                            </el-upload>
                            <div style="margin-top:5px;color:#999">最多可上传5张图片</div>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button type="primary" @click="submitComment">确 定</el-button>
                    <el-button @click="dialogVisible3 = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <payComp class="cusStyle" :clearGoodsList="clearGoodsList" @close="closePayComp" :showPayComp="showPayComp" v-show="showPayComp" :order="chosenItem2"></payComp>
        <tipBox1></tipBox1>
    </div>
</template>

<script>
import payComp from "@/components/payComp.vue"
import tipBox1 from "@/components/tipBox1.vue"
import {getOrderList,getUploadPolicy,getDictTypeList,addAfterSale,upOrderVoucher,addNewlyBuyersShow,
cancelOrder,getReceiveAccountList} from "@/axios/api"
export default {
    components: {
        payComp,tipBox1
    },
    data(){
        return{
            activeName:"",
            keyword:"",
            // condition:{
            //     orderType:0,
            //     daterange:"",
            //     nickname:"",
            //     evaluteStatus:0,
            //     tradingStatus:0,
            //     afterSale:0,
            // },
            condition:{
                payStatus:"",
                daterange:"",
                nickname:"",
                evaluteStatus:0,
                tradingStatus:0,
                afterSale:0,
            },
            orderTypes:[
                {
                    label:"全部",
                    value:0,
                },
                {
                    label:"待付款",
                    value:1,
                },
                {
                    label:"待发货",
                    value:2,
                },
                {
                    label:"待收货",
                    value:3,
                },
                {
                    label:"待评价",
                    value:4,
                },
            ],
            evaluteStatusList:[
                {
                    label:"全部",
                    value:0
                }
            ],
            tradingStatusList:[
                {
                    label:"全部",
                    value:0
                }
            ],
            afterSaleList:[
                {
                    label:"全部",
                    value:0
                }
            ],
            orderList:[],
            currentPage4:1,
            pageSize:12,
            total:0,
            dialogVisible:false,//申请售后弹框
            dialogVisible2:false,//上传凭证弹框
            dialogVisible3:false,//评价弹框
            afterSaleData:{
                type:"",
                remark:"",
                // annex:"",
                annex:[],
            },//申请售后表单数据
            afterSaleOptions:[
                // {
                //     label:"申请售后",
                //     value:1
                // },
                // {
                //     label:"投诉商家",
                //     value:2
                // },
            ],//售后类型
            voucherData:{
                orderSn:"",
                receiveAmount:"",
                receiveAccountType:"",
                receiveAccountId:"",
                payAccount:"",
                transSerialNumber:"",
                annex:[],
                remark:"",
            },
            receiveAccountTypeOpt:[
                {label:"线下转账/汇款",value:1},
                {label:"支付宝",value:2},
                {label:"微信",value:3},
            ],
            receiveAccountOpt:[],
            upVoucherRules:{
                receiveAmount:[
                    { required: true, message: '请输入收款金额', trigger: 'blur' },
                ],
                receiveAccountType:[
                    { required: true, message: '请选择收款方式', trigger: 'blur' },
                ],
                payAccount:[
                    { required: true, message: '请输入付款账户', trigger: 'blur' },
                ],
                annex:[
                    { required: true, message: '请上传关联凭证', trigger: 'blur' },
                ],
            },
            commentsData:{
                content:"",
                annex:[],
            },
            otherParam: {
                policy: '',
                signature: '',
                key: '',
                ossaccessKeyId: '',
                dir: '',
                host: '',
                // callback:'',
            },
            chosenItem:{},
            chosenItem2:{},
            payStatusOpt:[
                {label:"未支付",value:0},
                {label:"已支付",value:1},
                {label:"部分支付",value:2},
            ],

            clearGoodsList:[],
            showPayComp:false,
        }
    },
    methods: {
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize=val;
            this.gainOrderList()
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage4=val;
            this.gainOrderList();
        },

        handleClose1(done){
            done()
        },
        handleClose2(done){
            done()
        },
        handleAvatarSuccess(res,file){
            // console.log(res)
            // console.log(file)
            // this.afterSaleData.annex = URL.createObjectURL(file.raw);
            // this.afterSaleData.annex = _uploadRoot+this.otherParam.dir+file.name;
            this.afterSaleData.annex.push( _uploadRoot+this.otherParam.dir+file.name)
            // console.log(this.afterSaleData.annex)
        },
        handleAvatarSuccess2(res,file){
            // console.log(res)
            // console.log(file)
            // this.afterSaleData.annex = URL.createObjectURL(file.raw);
            this.voucherData.annex.push( _uploadRoot+this.otherParam.dir+file.name)
            // console.log(this.voucherData.annex)
        },
        handleAvatarSuccess3(res,file){
            // console.log(res)
            // console.log(file)
            // this.afterSaleData.annex = URL.createObjectURL(file.raw);
            this.commentsData.annex.push( _uploadRoot+this.otherParam.dir+file.name)
            // console.log(this.voucherData.annex)
        },
        beforeAvatarUpload(file){
            // console.log(file)
            console.log(44444)
            this.gainUploadPolicy();
            console.log(5555)
            this.otherParam.key=this.otherParam.dir+file.name;
        },
        // 获取上传参数
        async gainUploadPolicy(){
            await getUploadPolicy().then(res=>{
                console.log("上传参数",res);
                if(res.code==200){
                    this.otherParam.policy=res.data.policy;
                    this.otherParam.signature=res.data.signature;
                    this.otherParam.ossaccessKeyId=res.data.accessId;
                    this.otherParam.policy=res.data.policy;
                    this.otherParam.dir=res.data.dir;
                    this.otherParam.host=res.data.host;
                }
            })
        },

        handleClick(){
            this.gainOrderList();
        },
        // 获取订单列表
        gainOrderList(){
            getOrderList({
                pageNum:this.currentPage4,
                pageSize:this.pageSize,
                keyword:this.keyword,
                status:this.activeName
            }).then(res=>{
                console.log("订单列表",res);
                if(res.code==200){
                    if(res.data.rows){
                        res.data.rows.forEach(item=>{
                            item.checked=false;
                        })
                        this.orderList=res.data.rows;
                    }else{
                        this.orderList=[]
                    }
                    this.total=Number(res.data.total);
                }else{
                    // this.$message(res.msg||res.message)
                    this.$commonFun.wrongTip(this,res.msg);
                }
            })
        },

        // 点击售后
        clickAfterSale(type,item){
            // if(type==1){
            //     this.afterSaleData.type=1
            // }else{
            //     this.afterSaleData.type=2
            // }
            this.chosenItem=item;
            this.afterSaleData={
                type:"",
                remark:"",
                // annex:"",
                annex:[],
            };
            this.dialogVisible=true
        },
        // 获取售后类型
        gainAfterSaleType(){
            getDictTypeList({dictType:"AfterOrderType"}).then(res=>{
                console.log("售后类型",res);
                if(res.code==200){
                    this.afterSaleOptions=res.data.map(item=>{
                        return{
                            label:item.dictLabel,
                            value:item.dictValue
                        }
                    });
                }
            })
        },
        // 提交售后
        submitAfterSale(){
            var obj={
                orderId:this.chosenItem.orderId,
                afterType:this.afterSaleData.type,
                imgUrls:this.afterSaleData.annex.join(","),
                // imgUrls:"http://gips1.baidu.com/it/u=3874647369,3220417986&fm=3028&app=3028&f=JPEG&fmt=auto?w=720&h=1280",
                remark:this.afterSaleData.remark
            }
            addAfterSale(obj).then(res=>{
                console.log("提交售后结果",res)
            })
            // this.dialogVisible = false
        },
        // 点击订单详情
        clickOrderDetail(order){
            this.$router.push({
                path:"/personal/orderDetail",
                query:{orderId:order.orderId}
            })
        },
        // 点击评价
        clickComment(order){
            this.chosenItem2=order;
            this.commentsData={
                content:"",
                annex:[],
            };
            this.dialogVisible3=true;
        },
        // 点击上传凭证
        clickVoucher(order){
            this.chosenItem2=order;
            this.voucherData={
                orderSn:"",
                receiveAmount:"",
                receiveAccountType:"",
                receiveAccountId:"",
                payAccount:"",
                transSerialNumber:"",
                annex:[],
                remark:"",
            };
            this.dialogVisible2=true;
        },
        // 点击继续支付
        clickPayfor(order){
            this.chosenItem2=order;
            console.log(this.chosenItem2)
            var arr=order.orderGoodsList.map(item=>{
                item.goods._goodsNumber=Number(item.count);
                // this.$set(item.goods,'_goodsNumber',Number(item.count));
                return item.goods
            })
            console.log(arr)
            this.clearGoodsList=arr

            // this.showPayComp=true;
            this.$router.push({
                path:"/payPage",
                query:{s:order.orderId}
            })
        },
        // 关闭支付弹框
        closePayComp(){
            this.showPayComp=false
        },
        // 点击提交凭证
        submitVoucher(){
            this.$refs.voucherForm.validate(valid=>{
                // console.log(valid)
                if(valid){
                    var obj=JSON.parse(JSON.stringify(this.voucherData));
                    delete obj.annex;
                    obj.orderId=this.chosenItem2.orderId;
                    obj.orderSn=this.chosenItem2.orderSn;
                    obj.proof=this.voucherData.annex.join(',');

                    // var obj={
                    //     orderId:this.chosenItem2.orderId,
                    //     orderSn:this.chosenItem2.orderSn,
                    //     proof:this.voucherData.annex.join(',')
                    // }

                    upOrderVoucher(obj).then(res=>{
                        console.log("上传凭证结果",res);
                        if(res.code==200){
                            this.dialogVisible2=false,
                            this.$commonFun.wrongTip(this,"上传成功",'success');
                        }else{
                            this.$commonFun.wrongTip(this,res.msg);
                        }
                    })
                }
            })
            // if(this.voucherData.annex.length==0){
            //     this.$commonFun.wrongTip(this,"未上传凭证");
            //     return
            // }
        },
        // 点击提交评价
        submitComment(){
            if(this.commentsData.content==""){
                this.$commonFun.wrongTip(this,"未填写评价");
                return
            }
            var obj={
                orderId:this.chosenItem2.orderId,
                content:this.commentsData.content,
                images:this.commentsData.annex.map((img,i)=>{return{url:img,isMaster:i==0?"Y":"N"}})
            }
            addNewlyBuyersShow(obj).then(res=>{
                console.log("提交评价结果",res);
                if(res.code==200){
                    this.dialogVisible3=false,
                    this.$commonFun.wrongTip(this,"评价成功",'success');
                }else{
                    this.$commonFun.wrongTip(this,res.msg);
                }
            })
        },
        // 点击搜索
        clickSearch(){
            var obj={
                keyword:this.keyword,
            }
            this.gainOrderList(obj)
        },
        // 点击取消
        clickCancel(order){
            this.$confirm("确认取消订单吗",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                cancelOrder({
                    orderId:order.orderId
                }).then(res=>{
                    console.log("取消订单结果",res);
                    if(res.code==200){
                        this.$message.success("成功")
                        this.gainOrderList()
                    }else{
                        this.$commonFun.wrongTip(this,res.msg);
                    }
                })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
        },

        gainAccountList(){
            var obj={
                type:this.voucherData.receiveAccountType,
            }
            if(this.chosenItem2.bussinessId){
                obj.bussinessId=this.chosenItem2.bussinessId
            }
            getReceiveAccountList(obj).then(res=>{
                console.log("收款账户列表",res);
                if(res.code==200){
                    this.receiveAccountOpt=res.data;
                }
            })
        },

        clickPrint(order){
            // this.$router.push({
            //     path:"/personal/previewDetail",
            //     query:{orderId:order.orderId}
            // })

            var href=location.href.substring(0,location.href.indexOf("personal")-1);
            window.open(href+'/previewDetail?orderId='+order.orderId,"__blank")
        }
    },
    created() {
        // this.gainUploadPolicy();
        this.gainOrderList()
        this.gainAfterSaleType();
        // this.gainAccountList();
    },
    watch:{
        'voucherData.receiveAccountType'(n,o){
            this.gainAccountList()
        }
    }
}
</script>

<style lang="less" scoped>
.myOrder{
    height: 100%;
    .tab{
        .el-tabs{
            /deep/.el-tabs__item{
                font-size: 20px;
                font-weight: 700;
                color: #333;
                &.is-active{
                    color: rgba(255, 87, 51, 1);
                }
            }
            /deep/.el-tabs__active-bar{
                background-color: rgba(255, 87, 51, 1);
                height: 3px;
            }
        }
    }
    .search{
        margin-top: 20px;
        display: flex;
        align-items: center;
        .el-input{
            width: 358px;
        }
        &>span{
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(56, 56, 56, 1);
        }
    }
    .conditions{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &>span{
                margin-right: 10px;
                // width: 80px;
                font-size: 14px;
                font-weight: 400;
                color: #333;
            }
            &>.el-input{
                // flex: 1;
                width: 220px;
            }
            /deep/ .el-date-editor--daterange.el-input__inner{
                width: 460px
            }
            &:nth-child(3n+2){
                margin: 0 20px;
                margin-bottom: 20px;
                flex: 1;
            }
        }
    }
    .list{
        margin-top: 20px;
        height: calc(100% - 224px);
        overflow: auto;
        &::-webkit-scrollbar{
            width: 0;
        }
        .cell{
            display: flex;
            align-items: center;
            padding: 0 25px;
            .goods{
                flex: 1,
            }
            .unitPrice{
                width: 100px;
            }
            .goodsNumber{
                width: 100px;
            }
            .receiveUser{
                width: 60px;
            }
            .goodsOpe{
                width: 140px;
            }
            .payment{
                width: 140px;
            }
            .tradingStatus{
                width: 140px;
            }
            .tradingOpe{
                width: 140px;
            }

            .setDisable{
                height: 0;
                overflow: hidden;
            }
        }
        .thead{
            height: 50px;
            background: rgba(245, 245, 245, 1);
            font-size: 12px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
        }
        .tbody{
            .orderItem{
                border-left: 1px solid rgba(237, 237, 237, 1);
                border-right: 1px solid rgba(237, 237, 237, 1);
                .info{
                    height: 50px;
                    background: rgba(255, 245, 242, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 25px;
                    .left{
                        display: flex;
                        align-items: center;
                        &>span:nth-child(2){
                            font-size: 14px;
                            font-weight: 700;
                            color: rgba(102, 102, 102, 1);
                            margin-left: 5px;
                        }
                        &>span:nth-child(3){
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(102, 102, 102, 1);
                            margin-left: 10px;
                        }
                    }
                    .right{
                        i{
                            font-size: 21px;
                            color: rgba(166, 166, 166, 1);
                            cursor: pointer;
                        }
                    }
                }
                .goodsItem{
                    padding: 20px 25px;
                    align-items: stretch;
                    border-bottom: 1px solid rgba(237, 237, 237, 1);
                    &:last-child{
                        border-bottom: none;
                    }
                    .goods{
                        display: flex;
                        align-items: flex-start;
                        &>img{
                            width: 90px;
                            height: 68px;
                            object-fit: cover;
                        }
                        .goodsInfo{
                            flex: 1;
                            margin-left: 10px;
                            .name{
                                font-size: 12px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 17.38px;
                                color: #333;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                line-clamp: 3;
                            }
                            .otherInfo{
                                margin-top: 5px;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17.38px;
                                color: rgba(128, 128, 128, 1);

                            }
                            .deliveryTime{
                                margin-top: 15px;
                                font-size: 12px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 17.38px;
                                color: #333;
                            }
                        }
                    }
                    .unitPrice{
                        font-size:14px;
                    }
                    .goodsNumber{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .goodsOpe{
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        color: #333;
                        .ope_1{
                            color: #257e5d;
                        }
                        &>div{
                            margin-bottom: 5px;
                            cursor: pointer;
                        }
                    }
                    .payment{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .tradingStatus{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(56, 56, 56, 1);
                        cursor: pointer;
                        &>div{
                            margin-bottom: 5px;
                        }
                    }
                    .tradingOpe{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(56, 56, 56, 1);
                        .el-button{
                            height:30px;
                            line-height:30px;
                            padding-top:0;
                            padding-bottom:0;
                        }
                        .invoice{
                            margin-top: 5px;
                            cursor: pointer;
                        }
                        .voucher{
                            margin-top: 5px;
                            color: rgba(255, 87, 51, 1);
                            cursor: pointer;
                        }
                        .payfor{
                            margin-top: 5px;
                            color: #257e5d;
                            cursor: pointer;
                        }
                        .printOrder{
                            margin-top: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .empty{
                width: 100%;
                padding: 50px 0;
                display: block;
                // justify-content: center;
                text-align: center;
                &>img{
                    width: 200px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .pagi{
        margin-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                // background-color: rgba(35, 125, 92, 1)!important;
                background-color: rgba(200, 64, 57, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }

    .afterSaleDia,.upVoucherDia,.commentsDia{
        padding: 20px;
        .el-form{
            /deep/.el-form-item__content{
                .avatar-uploader .el-upload {
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                }
                .avatar-uploader .el-upload:hover {
                    border-color: #409EFF;
                }
                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 88px;
                    height: 88px;
                    line-height: 88px;
                    text-align: center;
                }
                .avatar {
                    width: 88px;
                    height: 88px;
                    display: block;
                }

                textarea{
                    resize:none;
                }
                &>.el-input{
                    width: 50%;
                }
            }
        }
        .dialog-footer{
            text-align: center;
            .el-button{
                width: 120px;
                height: 40px;
            }
        }
    }

    .cusStyle{
        position: fixed;
        // top: 269px;
        // top: 247px;
        // top: 285px;
        // left: calc(50% + 735px);
        top:50px;
        left:50%;
        transform: translateX(-50%);
        // top: 50%;
        // transform:translate(-50%,-50%);
        z-index:20100;
        background: #fff;
        // border: 1px solid #ccc;
        border: none;
        margin-left: 30px;
    }
}
</style>